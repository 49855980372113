@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.class {
    text-align: center;
    margin-bottom: 2rem;
    &Content {
        font-size: 3rem;
        margin-bottom: 0;
        border-radius: 1rem 1rem  0 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 1rem;
    }
    &Title {
        font-weight: bold;
        margin-bottom: 0;
    }
    &SubTitle {
        color: $body-color;
        padding: 1rem;
    }
}

@include breakpoint-up(sm) {
    .class {
        &Content {
            min-height: 14rem;
        }
    }
}
