.switch{
	height: 0;
	width: 0;
	visibility: hidden;
}


.switchLabel {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 26px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.switchLabel:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.switchLabelDisabled {
	cursor: not-allowed;
}

.switch:checked + .switchLabel {
	background: $primary;
}

.switch:checked + .switchLabel:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.switchLabel:active:after {
	width: 40px;
}


.switchDisabled:checked + .switchLabel {
	background: grey;
}