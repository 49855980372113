@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
.questionItem {
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    border-radius: 1rem;
    background: #fafafa;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &Inner {
        padding: 10px;
    }
    &Btn {
        padding: 0;
        display: flex;
        border: none;
        align-self: baseline;
        padding: 10px;
        border-top: 1px solid #CCC;
        width: 100%;
        cursor: pointer;
    }
    &Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &Done {
        background: #EFF6D7;
        svg {
            color: $accent-3
        }
    }

    &Meta {
        font-size: 1.2rem;
    }

}

.previewHeader {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: space-between;
    max-width: 80rem;
}

.wordBank {
    margin: 1rem 0;
    max-width: 60rem;
    &Item {
        display: inline-block;
        margin: 0 4px 4px 0;
        border-radius: .5rem;
        color: #fff;
        background: #696868;
        padding: 2px 10px;
        font-weight: bold;
    }

    &Delete {
        margin-left: 1rem;
        vertical-align: middle;
        line-height: 1;
    }
}


.task {
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: 100rem;
    align-items: flex-start;
    &Content {
        flex: 1;
        padding-right: 2rem;
    }
    &Sidebar {
        max-width: 28rem;
        flex-basis: 28rem;
        flex-grow: 1;

            background: #FAFAFA;
            border-radius: 1rem;
            padding: 2rem;
            text-align: center;
    }
}

.taskNav {
    background: #f3f3f3;
    display: flex;
    align-items: center;
    margin: 0 -3rem 2rem -3rem;
    padding: 1rem 2rem;
    &NewItem {
        position: relative;
        &Dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            width: 50rem;
            border: 1px solid #ccc;
            border-radius: 1rem;
            background: #fff;
            box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
            z-index: 900;
        }

        &Link {
            border: none;
            border-bottom: 1px solid #E1E1E1!important;
            margin-bottom: 1rem;
            padding: 1.4rem 2rem;
            width: 100%;
            display: flex;
            text-align: left;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &:last-of-type {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
        &Main {
            padding-right: 4rem;
            flex: 1
        }
    }
    &Add {
        color: $body-color;
        padding: 0;
        cursor: pointer;
        padding: 3px 15px;
        border-radius: 10rem;
        display: block;
        border: 2px solid #ccc;
        svg {
            top: 3px;
            position: relative;
        }
    }
    &Link {
        border-radius: 10rem;
        display: block;
        margin-right: .5rem;
        color: $body-color;
        position: relative;
        &Active {
            background: $accent-3;
            border: 2px solid $accent-3;
            color: #fff;
            .taskNavLinkInnerDraft {
                background: none;
                border: none;
            }
            .taskNavLinkInner {
                border: none;
            }
        }

    }
    &LinkInner {
        padding: 5px 15px;
        border-radius: 10rem;
        display: block;
        border: 2px solid #ccc;
        &Draft {
            background: #E1E1E1;
            border-color: #E1E1E1;
        }
    }
    &DoneIcon {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        color: #fff;
        background: darken($primary, 5%);
        position: absolute;
        bottom: -2px;
        text-align: center;
        right: 0;
        svg {
            position: relative;
            top: -3px;
        }
    }
}




.resources {
    li {
        font-weight: bold;
        font-size: 1.6rem;
        color: $body-color;
        margin: 1rem -2rem;
        width: calc(100% + 4rem);
        padding: 1rem;
        border-bottom: 1px solid #E1E1E1;
        border-top: 1px solid #E1E1E1;
    }
    &Link {
        display: flex;
        align-items: center;
        width: 100%;
        svg {
            margin-left: auto;
        }
    }

    &Img {
        flex-basis: 10rem;
        max-width: 10rem;
        margin-right: 1rem;
        margin-left: -1rem;
        flex-shrink: 0;
        padding: 0 1.5rem;
        img {
            margin: auto;
            width: 100%;
        }
    }

}

.taskEditHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}
.taskSettingsBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    svg {
        margin-right: .5rem;
    }
}

.resetBtn {
    border: none;
    display: flex;
    margin-left: auto;
}

.resetLoader {
    height: 24px;
    width: auto;
    margin: 14px 10px 0 auto;
}

.taskSettingsContainer {
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 200;
    background: #FFF;
    right: 0;
    bottom: 0;
    padding: 40px;
    overflow: scroll;
}

.taskInner {
    max-width: 750px;
}

@include breakpoint-up(sm) {
    .taskSettingsContainer {
        left: 100px;
    }
}

.previewContainer {
    display: flex;
}

.previewQuestions {
    margin-right: 20px;
}

.viewAllWords {
    text-decoration: underline;
    cursor: pointer;
}

.previewSide {
    max-width: 300px;
    min-width:300px;
}

.starCastLabel {
    font-size: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.starCastIcon {
    display: flex;
    align-items: center;
    margin: 0 3px;
    svg {
        color: $primary;
        margin-right: 3px;
    }
}
.templatesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
    &Item {
        display: flex;
    }
}

.pollExampleIcons {
    display: flex;
    align-items: center;
    color: #696868
}
.addItemHeader {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    align-items: flex-start;
    button {
        flex-basis: 5rem;
        flex-shrink: 0;
        text-align: right;
    }
}