@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.poll {
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: 100rem;
    align-items: flex-start;
    &Content {
        flex: 1;
        padding-right: 2rem;
    }

    &Sidebar {
        max-width: 28rem;
        flex-basis: 28rem;
        flex-grow: 1;
        &Panel {
            background: #FAFAFA;
            border-radius: 1rem;
            padding: 2rem 2rem 2rem 2rem;
            text-align: center;
        }
    }
}

.reactionSelect {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.4rem;
    &Locked {
        .reactionSelectItem {
            cursor: default;
        }
    }
    &Item {
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
        border-radius: 1rem;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &Active {
            background: #f2f2f2;
        }
    }
}

.pollResults {
    margin-top: 2rem;
    padding-right: 2rem;
}

.student {
    border-bottom: 1px solid #e2e2e2;
    margin: .5rem 0;
    padding: .5rem 0;
    display: flex;
    align-items: flex-start;
    &Main {
        flex: 1;
    }
    &Reaction {
        flex-basis: 10rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &Comment {
        margin-top: 1.5rem;
        font-style: italic;
    }
}

.overallSummary {
    flex: 1;
    margin-right: 1.5rem;
}
.barItem {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}
.barIcon {
    margin-right: .5rem;
    display: flex;
    justify-content: flex-end;
}
.overallSummaryStars {
    .barIcon {
        flex-basis: 8.4rem;
        flex-shrink: 0;
    }
}
.barHolder {
    width: 100%;
}
.barHolder,
.barValue {
    height: 2.4rem;
    display: block;
    min-width: 1px;
}
.barValue {
        width: auto;
}
.barValueUp {
    background: $primary;
}
.barValueDown {
    background: red;
}
.barValueMid {
    background: rgb(139, 138, 153);
}
.barValueEmpty {
    background: #dadada;
}