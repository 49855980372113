.editor {
    border: 1px solid lighten($gray-1, 8%);
    padding: 1rem;
}

.tagvocab {
    background: #FFD653;
    padding:1px 3px;
    border-radius: 2px;
    color: #fff;
}