
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  min-height: 100%;
}

body {
    font-family: $body-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: left;
    color: $body-color;
    position: relative;
    @include breakpoint-up(md) {
        font-size: $base-desktop-font-size;
    }
}

.app {
    &__layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        position: relative;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: linear-gradient(to bottom, $primary-dark 0%, $primary calc(100% - 11rem)) no-repeat left top;
            background-size: cover;
            z-index: -1;
        }

    }
    &__content {
        padding: 7rem 2rem 13rem 2rem;
    }
}

.impersonating {
    background: blue;
    color: white;
    font-size: 1rem;
    left: 0;
    opacity: 0.5;
    padding: .5rem;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 2000;
}

@include breakpoint-up(sm) {
    .app {
        &__layout {
            background: #fff;
        }
        &__content {
            padding: 2rem 3rem 2rem 13rem;
        }
    }

}

@media print {
    .app__layout {
        display: block;
        min-height: none;
        overflow: hidden;
        &:before {
            display: none;
            position: static;
        }
    }
    .app__content {
        padding: 0;
    }
}