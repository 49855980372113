@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.entryModalBG {
    background: #FFF;
    position: absolute;
    left: 100px;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 40px;
}

.entryHeader {
    border-bottom: 1px solid #CCC;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.entryClose {
    border: none;
    display: flex;
    padding: 5px;
    margin-left: auto;
    cursor: pointer;
}

.entry {
    &Area {
        flex: 5;
    }
    &Sidebar {
        flex: 3;
        margin-top: 2rem;
    }
}

.loginCode {
    font-size: 1.4rem;
    strong {
        font-weight: bold;
    }
}
.chapStatus {
    align-items: center;
    display: flex;
    color: #8e8e8e;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    background: #e7e7e7;
    border-radius: 10px;
    padding: 5px 10px;
    align-self: flex-start;
}

.statusIcon {
    margin-right: 5px;
}
@include breakpoint-up(sm) {
    .restoreOpts {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        p {
            margin-top: 0;
        }
    }
}

@include breakpoint-up(md) {
    .entry {
        &Container {
            display: flex;
        }
        &Sidebar {
            margin-left: 2rem;
            margin-top: 0;
        }
    }
}

.statusEntry {
    margin-left: 5px;
    color: #80b814;
    text-decoration: underline;
}

.manageEntryBtn {
    align-items: center;
    border-radius: 10px;
    border: 1px solid #CCC;
    display: flex!important;
    justify-content: left;
    min-width: 19.5rem;
    padding: 5px 10px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
}

.entryAction {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-top: 10px;
}

.entryActionIcon {
    margin-right: 5px;
}


.entryActionLabel {
    margin-right: 10px;
}

.subHeader {
    font-weight: bold;
    color: #8e8e8e;
}