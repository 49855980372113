
.page-header {
    min-height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
    &__back {
        border: none;
        margin-right: 2rem;
        padding: 0;
        cursor: pointer;
    }
    &__content {
        color: #fff;
        display: flex;
        &__title {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            svg {
                margin-right: .5rem;
            }
        }
        &__subtitle {
            font-size: 1.2rem;
        }
        &__link {
            cursor: pointer;
        }
        &__breadcrumbs {
            margin-bottom: .8rem;
            list-style: none;
            a {
                color: #fff;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-right: .3rem;
                }
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    &__actions {
        background: #fff;
        padding: 1rem;
        margin: 1rem 0 2rem 0;
        border-radius: 1rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
       > ul, li {
            display: flex;
        }
       > ul {
            justify-content: space-between;
        }
       >ul > li {
            cursor: pointer;
            &:not(:last-child) {
                margin-right: 1.5rem;
            }
        }
    }
    &__dropdown ul{
        left: 0;
        right: auto;
    }
    &__dropdown a,
    &__dropdown button {
        font-size: 1.5rem;
    }

}

@include breakpoint-up(sm) {
    .page-header {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid lighten($gray-1, 10%);
        margin: -2rem -3rem 2rem -3rem ;
        width: calc(100% + 6rem);
        padding: 1rem 3rem;
        &__content {
            color: $body-color;
            &__breadcrumbs {
                a {
                    color: $body-color;
                }
            }
        }
        &__actions {
            padding: 0;
            background: 0;
            margin: 0;
            display: flex;
            box-shadow: none;
            svg {
                color: lighten($body-color, 10%);
            }
        }
        &__dropdown ul{
            right: 0;
            left: auto;
        }

    }
}
