@import '../../styles/variables.scss';

.unit {
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    border-radius: 1rem;
    margin-bottom: 2rem;
    max-width: 65rem;

    &Header {
        padding: 1rem 2rem;
        border-bottom: 1px solid #ccc;
        display: flex;
        &Icon {
            margin-right: 1rem;
        }
    }

    &Topics {
        &Item {
            &Content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 1rem 2rem;
            }
            &Title {
                display: flex;
                margin-bottom: 0;
            }
            cursor: pointer;
            border-bottom: 1px solid #ccc;
            color: $body-color
        }

        &Label {
            width: 26px;
            height: 26px;
            border-radius: 13px;
            text-align: center;
            line-height: 24px;
            margin-right: 1rem;
            display: inline-block;
            border: 1px solid #ccc;
            font-size: 1.5rem;

            &Active {
                color: #fff;
                background: #3A3A3A;
                border-color: #3A3A3A;
            }
        }

    }
}

.stats {
    display: flex;
    
    &Item {
        color: #E1E1E1;

        &Active {
            color: $accent-3;
        }

        &Completed {
            color: $primary;
        }
    }
}

.taskCompletedBar {
    height: 2px;
    background: #80B814;
    position: relative;
    bottom: -1px;

}