@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';
.entry {
    &Area {
        flex: 6;
        &Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &Sidebar {
        flex: 2;
        margin-top: 2rem;
    }
    &HistoryNav {
        &Btn {
            display: block;
            padding: .5rem;
            margin-bottom: .5rem;
            width: 100%;
            border: none;
            text-align: left;
            cursor: pointer;
            border-bottom: 1px solid $gray-1;
            &:hover {
                opacity: .8;
            }
            &:focus {
                outline: none;
            }
            &Active {
                border-color: $tertiary;
            }
        }
    }

}
@include breakpoint-up(md) {
    .entry {
        &Area {
            &Card {
                position: sticky;
                top: 0;
            }
        }
        &Container {
            display: flex;
        }
        &Sidebar {
            margin-left: 2rem;
            margin-top: 0;
        }
    }
}
