@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';
.student {
    align-items: center;
    border-bottom: 1px solid lighten($gray-1, 5%);
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    margin: 0 -2rem;
    &Inactive {
        background: lighten($gray-1, 12%);
    }
    &RankAndName {
        align-items: center;
        display: flex;
    }
    &Rank,
    &Name {
        margin-right: 1rem;

    }
    &Name {
        cursor: pointer;
        &:hover {
            color: #979797;
        }
    }
    &Rank {
        width: 3rem;
    }
    &Actions {
        display: flex;
        margin: 0 -.5rem;
    }
    &Action,
    &Checkbox {
        align-items: center;
        border: none;
        cursor: pointer;
        display: flex;
        font-size: 1.8rem;
        margin: 0 .5rem;
        padding: 0;
        color: darken($gray-1, 10%);
        &Icon {
            &Highlight {
                color: $secondary;
            }
        }
        &Disabled {
            cursor: default;
        }
        &Count {
            font-size: 1.8rem;
        }
    }
    &Checkbox {
        margin-left: 0;
    }
}

@include breakpoint-up(sm) {
    .student {
        padding: 1rem 0;
        margin: 0;
        &Inactive {
            padding-left: .5rem
        }
    }
}
@include breakpoint-up(md) {
    .student {
        &Actions {
            margin: 0 -1rem;
        }
        &Action,
        &Checkbox {
            font-size: 2.4rem;
            margin: 0 1rem;
        }
    }
}
