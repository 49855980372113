.gemButton {
    align-items: center;
    border-radius: 10px;
    border: 1px solid #CCC;
    display: flex!important;
    justify-content: left;
    min-width: 19.5rem;
    padding: 5px 10px;
    cursor: pointer;
    width: 100%;
    &Img {
        margin-right: .5rem;
        width: 2rem;
    }
    &Confirmed {
        height: 2.1rem;
        width: 2.1rem;
    }
}