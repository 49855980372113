@import '../../../../../../styles/variables.scss';

.vocabList {
    margin-bottom: 1rem;
    margin-top: .5rem;

    &Item {
        display: inline-block;
        margin: 0 5px 5px 0;
        background: #f3f3f3;
        padding: 1px 10px;
        border-radius: .5rem;
        transition: all 0.5s ease;
        font-weight: bold;
        &Used {
            background:$secondary;
            color: #fff;
            // animation: pulse 1s;
        }
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(252, 198, 45, 1);
    }

    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(252, 198, 45, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(252, 198, 45, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(252, 198, 45, 1);
        box-shadow: 0 0 0 0 rgba(252, 198, 45, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 15px rgba(252, 198, 45, 0);
        box-shadow: 0 0 0 15px rgba(252, 198, 45, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(252, 198, 45, 0);
        box-shadow: 0 0 0 0 rgba(252, 198, 45, 0);
    }
}