
$gutter: 2rem !default;
$grid-columns: 12 !default;

@mixin grid-numbered($no) {
  flex: 0 0 percentage($no/$grid-columns);
  max-width: percentage($no/$grid-columns);
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -$gutter/2;
  margin-left: -$gutter/2;
}

%grid-column {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right:  $gutter/2;
  padding-left: $gutter/2;
}


@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);

  .col#{$infix} {
    @extend %grid-column;
  }
  @for $i from 1 through $grid-columns {
    .col#{$infix}-#{$i} {
      @extend %grid-column;
    }
  }

  @include breakpoint-up($point, $grid-breakpoints) {
    .col#{$infix} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @include grid-numbered($i);
      }
    }
  }
}
