
.basic-form {
	&__group {
        align-items: center;
        display: flex;
        margin-bottom: 1rem;
        &--horizontal {
            align-items: flex-start;
            flex-direction: column;
            .basic-form__text-label {
                width: auto;
            }
        }
    }
    &__text-label {
        width: 20rem;
        display: flex;
        justify-content: space-between;
        margin-right: .5rem;
        padding: .6rem 0;
        &--slim {
            display: block;
            font-weight: bold;
            width: 100px;
        }
        &--auto {
            margin-right: 1rem;
            width: auto;
        }
    }
    &__price {
        font-weight: bold;
    }
	&__text-box,
    &__text-select,
    &__text-area {
		border: 1px solid $gray-2;
		color: #4A4A4A;
		font-size: 1.6rem;
		line-height: 1.2;
		padding: .8rem 1rem;
		width: 100%;
        background: #fff;
        border-radius: 1rem;
        resize: vertical;
		margin-bottom: 1rem;
		&:focus {
			box-shadow: none;
			outline: none;
        }
        &:disabled {
            color: lighten(#979797, 20%);
        }
	}
    &__text-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #fff url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' fill='%23999999' viewBox='0 0 32 32'%3e%3cpath d='M4.516,6.742L16,18.227L27.484,6.742L31,10.257L16,25.258L1,10.257L4.516,6.742z'/%3e%3c/svg%3e") no-repeat calc(100% - 10px) 50%;
        background-size: 1.4rem;
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #ccc;
        }
    }
	&__group--check {
		position: relative;
	}
	&__check-label {
		padding-left: 2.6rem;
	}
	&__check-box {
		position: absolute;
		opacity: 0;
		z-index: 10;
		~ label:before {
			position: absolute;
			left: 0;
			display: block;
			width: 16px;
			height: 16px;
			text-align: center;
			line-height: 15px;
			pointer-events: none;
			content: "";
			user-select: none;
			background: #fff;
			border: 1px solid #979797;
			top: 50%;
			border-radius: 2px;
			transform: translateY(-50%);
			cursor: pointer;
		}
		&:checked ~ label:before {
			content: "";
			color: #fff;
			background: #2BBEFA;
			border-color: #2BBEFA;
		}
		&:checked ~ label:after {
			content: '';
			position: absolute;
			top: 8px;
			left: 3px;
			width: 10px;
			height: 5px;
			border: 2px solid #fff;
			border-width: 0 0 2px 2px;
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
    &__hint {
        color: red;
        font-size: 1.2rem;
        margin: -1rem 0 1rem;
	}
	&__errors {
		align-items: center;
		background: lighten(red, 50%);
		border: 1px solid red;
		color: red;
		display: flex;
		font-size: 1.6rem;
		margin: 2rem 0;
		padding: 1rem;
		&__icon {
			margin-right: .7rem;
		}
	}
}
