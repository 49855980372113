@import '../../styles/variables';
@import '../../styles/mixins';

.tabs {
    display: flex;
    margin: 0 -10px;
    &WithPanel {
        background: #fff;
        padding: 1rem;
        border-radius: 1rem;
        margin: 0;
    }
}


@include breakpoint-up(sm) {
    .tabs {
        &WithPanel {
            background: none;
            padding: 0;
            margin: 0 -10px;

        }
    }
}
